import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import style from './index.module.css'

function Index(props) {

    let [headIndex, setHeadIndex] = useState(-1);
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setHeadIndex(-1);
                break;
            case "/product":
                setHeadIndex(0);
                break;
            case "/contact":
                setHeadIndex(1);
                break;
            case "/use":
                setHeadIndex(2);
                break;
            case "/about":
                setHeadIndex(3);
                break;
            case "/news":
                setHeadIndex(4);
                break;
     
            default:
                setHeadIndex(-1);
        }
    }, [location]);

    return (
        <div className={style['box']}>
            <ul className={style['container']}>
                <li className={`${style['one']} ${style['logo_box_white']}`}>
                    <div className={`${style["logo_box"]} `}>
                        <Link to='/'><img src='/logo.png' alt="黑菠萝科技有限公司" /></Link>
                    </div>
                </li>
                {/* <li className={`${style['one']} ${style['logo_box_black']}`}>
                    <div className={`${style["logo_box"]} `}>
                        <Link to='/'><img src='/05cebaa7117ae5954399c859aca65bd.png' alt="黑菠萝科技有限公司" /></Link>
                    </div>
                </li> */}
                <li className={`${style['one']} ${style['hover-product']}`}>
                    <Link className={`${style["aa"]} `} title="产品中心" to="/product" onClick={() => { setHeadIndex(0) }}>
                        产品中心
                        <span className={`${headIndex === 0 ? `${style['click-bottom-line']}` : ''}`}></span>
                    </Link>
                    <div className={style['product-nav-list']}>
                        <div className={`${style["product-nav-item"]}`}>
                            <Link title="产品中心" to="/product#hardware">智慧硬件</Link>
                        </div>
                        <div className={`${style["product-nav-item"]}`}>
                            <Link title="产品中心" to="/product#software">智慧软件</Link>
                        </div>
                        <div className={`${style["product-nav-item"]}`}>
                            <Link title="产品中心" to="/product#iot">IOT平台</Link>
                        </div>
                    </div>
                </li>

                <li className={style['one']}>
                    <Link className={style["aa"]} title="服务支持" to="/contact" onClick={() => { setHeadIndex(1) }}>
                        服务支持
                        <span className={`${headIndex === 1 ? `${style['click-bottom-line']}` : ''}`}></span>
                    </Link>
                </li>

                <li className={style['one']}>
                    <Link className={style["aa"]} title="应用场景" to="/use" onClick={() => { setHeadIndex(2) }}>
                        应用场景
                        <span className={`${headIndex === 2 ? `${style['click-bottom-line']}` : ''}`}></span>
                    </Link>
                </li>
                <li className={style['one']}>
                    <Link className={style["aa"]} title="应用场景" to="/news" onClick={() => { setHeadIndex(4) }}>
                        新闻动态
                        <span className={`${headIndex === 4 ? `${style['click-bottom-line']}` : ''}`}></span>
                    </Link>
                </li>
                <li className={style['one']}>
                    <Link className={style["aa"]} title="关于我们" to="/about" onClick={() => { setHeadIndex(3) }}>
                        关于我们
                        <span className={`${headIndex === 3 ? `${style['click-bottom-line']}` : ''}`}></span>
                    </Link>
                </li>
                <div className={style['hover-background']}></div>
            </ul>
        </div>
    )
}

export default Index