import React,{Component} from 'react';
export default function asyncComponent(importComponent) {
    class AsyncComponent extends Component {
        constructor() {
            super();

            this.state = {
                component: null
            }
        }

        async componentDidMount() {
            try {
               
            } catch (error) {
                
            }
            const { default: component } = await importComponent();
            this.setState({component: component})
        }

        render() {
            const C = this.state.component;

            return C ? <C {...this.props} /> : null;
        }
    }

    return AsyncComponent;
}