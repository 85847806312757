
import React, { Component } from 'react';
import { Layout, Breadcrumb } from "antd";
import asyncComponent from './utils/common/asyncComponent'
import MyHead from './Head/index'
import MyFooter from './Footer/index'
import moment from 'moment';


import { Route, Routes, Outlet } from 'react-router-dom'

import './App.css';


class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      status: false,
      breadcrumbItems: [],
      swiperimgsdata: [],
      swipertextsdata: [],
      propagandas: [],
      companyinfo: {},
      myrouters: [
        {
          path: '/',
          component: asyncComponent(() => import('./pages/Home')),
        },
        {
          path: '/product',
          component: asyncComponent(() => import('./pages/Product/index')),
        }
        ,
        {
          path: '/use',
          component: asyncComponent(() => import('./pages/UsePlace')),
        }
        , {
          path: '/news',
          component: asyncComponent(() => import('./pages/News')),
        },
        {
          path: '/newscontent1',
          component: asyncComponent(() => import('./pages/News/newscontent1')),
        }
        ,
        {
          path: '/newscontent2',
          component: asyncComponent(() => import('./pages/News/newscontent2')),
        }
        ,
        {
          path: '/newscontent3',
          component: asyncComponent(() => import('./pages/News/newscontent3')),
        },
        {
          path: '/newscontent4',
          component: asyncComponent(() => import('./pages/News/newscontent4')),
        }
        ,
        {
          path: '/contact',
          component: asyncComponent(() => import('./pages/ContactType'))
        }
        ,
        {
          path: '/about',
          component: asyncComponent(() => import('./pages/About')),
        }
      ]
    }
  }

  componentDidMount() {
    // var c = new Date('2022-09-29T16:00:00Z');
    // var b = moment(c).format('YYYY-MM-DD HH:mm:ss');
    // debugger;
    // console.log(b);
  }
  routerUpdate = () => {
    console.log("改变页面");
    this.setState({ status: !this.state.status })
  }
  withRouter = (Component) => {
    <Route element={Component} />
  }

  render() {
    const { Header, Content, Footer } = Layout;
    const { breadcrumbItems, myrouters } = this.state;
    return (
      <div className="App" >
        <Layout className="layout">
          <Header className='layout_head'>
            <MyHead></MyHead>
          </Header>
          <Content className='layout_content'>
            <React.Fragment>
              <Breadcrumb>{breadcrumbItems}</Breadcrumb>

              <Routes>
                {myrouters.map((item, index) => (
                  <Route key={index} path={item.path} element={<item.component />}></Route>
                ))}
              </Routes>
            </React.Fragment>
          </Content>
          <Footer className='layout_foot'>
            <MyFooter></MyFooter>
          </Footer>
        </Layout>
      </div>
    )
  }
}
export default App;
