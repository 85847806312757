import style from './index.module.css'

function Index() {
 return( <div className={style['foot']}>
<div className={style['foot_title']}>
    关于黑菠萝科技
</div>
<div className={style['foot_title_text']}>
    黑菠萝科技是值得信赖的智慧餐饮解决方案服务商，致力于将先进的自动化、数字化和AI技术应用于餐饮产业的经营实践。
</div>
    <div className={style['foot_xhx']}></div>
    <div className={style['foot_info']}>
    <div >电话:028-83222560</div>
    <div >地址:成都市高新区环球中心N5-911</div>
    <div>邮箱:SCHBLKJ@163.COM</div>
    </div>
    <div className={style['foot_QCode']}>
    <img src='/qcode.png' alt='二维码'></img>
    <img src='/dyh.png' alt='二维码'></img>
    </div>
    <div className={style['foot_text1']}>在抖音或微信中搜索‘四川黑菠萝科技有限公司’订阅智慧餐饮前沿资讯</div>
    <div className={style['foot_text2']}> <a href="https://beian.miit.gov.cn"> 蜀ICP备2021013859号-1</a></div>
   
   </div>
 )

}
export default Index;
